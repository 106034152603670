// save to storage
export const saveToLocalStorage = (key: string, value: string | object) => {
  if(typeof window !== 'undefined') {
    if (typeof value !== 'string') value = window.JSON.stringify({...value})
    return window.localStorage.setItem(key, value);
  }
}

// get from storage
export const getFromLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    const val = window.localStorage.getItem(key);

    try {
      return JSON.parse(val || '')
    } catch(err) {
      return val;
    }
  }
}

export const isInLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    return key in localStorage
  }
  return false
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(`Not Found! Params: ${message}`);
    this.name = 'NotFoundError';
    this.stack = new Error().stack; // Optional
  }
}
// NotFoundError.prototype = Object.create(Error.prototype);

export class ExpiredError extends Error {
  constructor(message: string) {
    super(`Expired! Params: ${message}`);
    this.name = 'ExpiredError';
    this.stack = new Error().stack; // Optional
  }
}
// ExpiredError.prototype = Object.create(Error.prototype);


// Storage Cookies
export class Storage {
  _SIZE;
  sync;
  defaultExpires;
  enableCache;
  _s;
  _innerVersion;
  cache;
  isPromise;
  _mapPromise;
  _m!: { __keys__?: any; innerVersion?: any; index?: number;[key: string]: any; };

  constructor(options: {
    size: number;
    sync: Record<string, any>;
    defaultExpires: undefined | number;
    enableCache: boolean;
    storageBackend: WindowLocalStorage['localStorage'] | undefined;
  } = {
    size: 1000,
    sync: {},
    enableCache: true,
    defaultExpires: 1000 * 3600 * 24,
    storageBackend: typeof window !== 'undefined' ? window.localStorage : undefined
  }) {
    this._SIZE = options.size || 1000; // maximum key-ids capacity
    this.sync = options.sync || {}; // remote sync method
    this.defaultExpires = options.defaultExpires !== undefined ? options.defaultExpires : 1000 * 3600 * 24;
    this.enableCache = options.enableCache !== false;
    this._s = options.storageBackend || (typeof window !== 'undefined' ? window.localStorage : options.storageBackend);
    this._innerVersion = 11;
    this.cache = {} as any;

    if (this._s && this._s.setItem) {
      try {
        var promiseTest = this._s.setItem('__react_native_storage_test', 'test');
        // @ts-ignore
        this.isPromise = !!(promiseTest && promiseTest.then);
      } catch (e) {
        console.warn(e);
        delete this._s;
        throw e;
      }
    } else {
      console.warn(`Data would be lost after reload cause there is no storageBackend specified!
      \nEither use localStorage(for web) or AsyncStorage(for React Native) as a storageBackend.`);
    }

    this._mapPromise = (this.getItem?.('map') as any)?.then?.((map: string) => {
      this._m = this._checkMap((map && JSON.parse(map)) || {});
    });
  }
  getItem(key: string): Promise<any> | null | string {
    return this._s
      ? this.isPromise
        ? this._s.getItem(key)
        : Promise.resolve(this._s.getItem(key))
      : Promise.resolve();
  }
  setItem(key: string, value: string) {
    return this._s
      ? this.isPromise
        ? this._s.setItem(key, value)
        : Promise.resolve(this._s.setItem(key, value))
      : Promise.resolve();
  }
  removeItem(key: string) {
    return this._s
      ? this.isPromise
        ? this._s.removeItem(key)
        : Promise.resolve(this._s.removeItem(key))
      : Promise.resolve();
  }
  _initMap() {
    return {
      innerVersion: this._innerVersion,
      index: 0,
      __keys__: {},
    };
  }
  _checkMap(map: { innerVersion?: any; }) {
    if (map && map.innerVersion && map.innerVersion === this._innerVersion) {
      return map;
    } else {
      return this._initMap();
    }
  }
  _getId(key: string, id: string) {
    return key + '_' + id;
  }
  _saveToMap(params: { key: any; id: any; data: any; }) {
    let { key, id, data } = params,
      newId = this._getId(key, id),
      m = this._m as any;
    if (m[newId] !== undefined) {
      // update existing data
      if (this.enableCache) this.cache[newId] = JSON.parse(data);
      return this.setItem('map_' + m[newId], data);
    }
    if (m[m.index] !== undefined) {
      // loop over, delete old data
      let oldId = m[m.index];
      let splitOldId = oldId.split('_');
      delete m[oldId];
      this._removeIdInKey(splitOldId[0], splitOldId[1]);
      if (this.enableCache) {
        delete this.cache[oldId];
      }
    }
    m[newId] = m.index;
    m[m.index] = newId;

    m.__keys__[key] = m.__keys__[key] || [];
    m.__keys__[key].push(id);

    if (this.enableCache) {
      const cacheData = JSON.parse(data);
      this.cache[newId] = cacheData;
    }
    let currentIndex = m.index;
    if (++m.index === this._SIZE) {
      m.index = 0;
    }
    this.setItem('map_' + currentIndex, data);
    this.setItem('map', JSON.stringify(m));
  }
  save(params: { key: any; data: any; expires: any; id?: any; rawData?: any; }) {
    const { key, id, data, rawData, expires = this.defaultExpires } = params;
    if (key.toString().indexOf('_') !== -1) {
      console.error('Please do not use "_" in key!');
    }
    let dataToSave: any = { rawData: data };
    if (data === undefined) {
      if (rawData !== undefined) {
        console.warn('"rawData" is deprecated, please use "data" instead!');
        dataToSave.rawData = rawData;
      } else {
        console.error('"data" is required in save()!');
        return;
      }
    }
    let now = Date.now();
    if (expires !== null) {
      dataToSave.expires = now + expires;
    }
    dataToSave = JSON.stringify(dataToSave);
    if (id === undefined) {
      if (this.enableCache) {
        const cacheData = JSON.parse(dataToSave);
        this.cache[key] = cacheData;
      }
      return this.setItem(key, dataToSave);
    } else {
      if (id.toString().indexOf('_') !== -1) {
        console.error('Please do not use "_" in id!');
      }
      return this._mapPromise.then(() =>
        this._saveToMap({
          key,
          id,
          data: dataToSave,
        }),
      );
    }
  }
  get(key: any) {
    return this.load({
      key,
    }).catch((err: any) => {
      return;
    });
  }
  set(key: any, value: any, options?: {
    expires?: number // day
  }) {
    return this.save({
      key,
      data: value,
      expires: options?.expires ? options?.expires * 1000 * 3600 * 24 : this.defaultExpires
    });
  }
  getBatchData(querys: any[]) {
    return Promise.all(querys.map((query: any) => this.load(query)));
  }
  async getBatchDataWithIds(params: { key: any; ids: any; syncInBackground: any; syncParams: any; }) {
    let { key, ids, syncInBackground, syncParams } = params;
    const tasks = ids.map((id: any) =>
      this.load({
        key,
        id,
        syncInBackground,
        autoSync: false,
        batched: true,
      }),
    );
    const results = await Promise.all(tasks);
    const missingIds: any[] = [];
    results.forEach(value => {
      if (value.syncId !== undefined) {
        missingIds.push(value.syncId);
      }
    });
    if (missingIds.length) {
      const syncData = await this.sync[key]({
        id: missingIds,
        syncParams,
      });
      return results.map(value => {
        return value.syncId ? syncData.shift() : value;
      });
    } else {
      return results;
    }
  }
  _lookupGlobalItem(params: { key: string; autoSync?: any; syncInBackground?: any; syncParams?: any; }) {
    const { key } = params;
    if (this.enableCache && this.cache[key] !== undefined) {
      return this._loadGlobalItem({ ret: this.cache[key], ...params });
    }
    return (this.getItem(key) as any)?.then((ret: any) => this._loadGlobalItem({ ret, ...params }));
  }
  _loadGlobalItem(params: { key: any; ret: any; autoSync?: any; syncInBackground?: any; syncParams?: any; }) {
    let { key, ret, autoSync, syncInBackground, syncParams } = params;
    if (ret === null || ret === undefined) {
      if (autoSync && this.sync[key]) {
        return this.sync[key]({ syncParams });
      }
      throw new NotFoundError(JSON.stringify(params));
    }
    if (typeof ret === 'string') {
      ret = JSON.parse(ret);
      if (this.enableCache) {
        this.cache[key] = ret;
      }
    }
    let now = Date.now();
    if (ret.expires < now) {
      if (autoSync && this.sync[key]) {
        if (syncInBackground) {
          try {
            this.sync[key]({ syncParams, syncInBackground });
          } catch (e) {
            // avoid uncaught exception
          }
          return ret.rawData;
        }
        return this.sync[key]({ syncParams, syncInBackground });
      }
      throw new ExpiredError(JSON.stringify(params));
    }
    return ret.rawData;
  }
  _noItemFound(params: { ret?: any; key: any; id: any; autoSync?: any; syncParams?: any; }) {
    let { key, id, autoSync, syncParams } = params;
    if (this.sync[key]) {
      if (autoSync) {
        return this.sync[key]({ id, syncParams });
      }
      return { syncId: id };
    }
    throw new NotFoundError(JSON.stringify(params));
  }
  _loadMapItem(params: { ret?: any; key: any; id: any; autoSync?: any; batched?: any; syncInBackground?: any; syncParams?: any; }) {
    let { ret, key, id, autoSync, batched, syncInBackground, syncParams } = params;
    if (ret === null || ret === undefined) {
      return this._noItemFound(params);
    }
    if (typeof ret === 'string') {
      ret = JSON.parse(ret);
      const { key, id } = params;
      const newId = this._getId(key, id);
      if (this.enableCache) {
        this.cache[newId] = ret;
      }
    }
    let now = Date.now();
    if (ret.expires < now) {
      if (autoSync && this.sync[key]) {
        if (syncInBackground) {
          try {
            this.sync[key]({ id, syncParams, syncInBackground });
          } catch (e) {
            // avoid uncaught exception
          }
          return ret.rawData;
        }
        return this.sync[key]({ id, syncParams, syncInBackground });
      }
      if (batched) {
        return { syncId: id };
      }
      throw new ExpiredError(JSON.stringify(params));
    }
    return ret.rawData;
  }
  _lookUpInMap(params: { key: any; id: any; autoSync?: any; syncInBackground?: any; batched?: any; syncParams?: any; }) {
    let ret;
    const m = this._m;
    const { key, id } = params;
    const newId = this._getId(key, id);
    if (this.enableCache && this.cache[newId]) {
      ret = this.cache[newId];
      return this._loadMapItem({ ret, ...params });
    }
    if (m[newId] !== undefined) {
      return (this.getItem('map_' + m[newId]) as any).then((ret: any) => this._loadMapItem({ ret, ...params }));
    }
    return this._noItemFound({ ret, ...params });
  }
  remove(params: { key: any; id: any; }) {
    return this._mapPromise.then(() => {
      let m = this._m;
      let { key, id } = params;

      if (id === undefined) {
        if (this.enableCache && this.cache[key]) {
          delete this.cache[key];
        }
        return this.removeItem(key);
      }
      let newId = this._getId(key, id);

      // remove existing data
      if (m[newId] !== undefined) {
        if (this.enableCache && this.cache[newId]) {
          delete this.cache[newId];
        }
        this._removeIdInKey(key, id);
        let idTobeDeleted = m[newId];
        delete m[newId];
        this.setItem('map', JSON.stringify(m));
        return this.removeItem('map_' + idTobeDeleted);
      }
    });
  }
  _removeIdInKey(key: string | number, id: any) {
    const indexTobeRemoved = (this._m.__keys__[key] || []).indexOf(id);
    if (indexTobeRemoved !== -1) {
      this._m.__keys__[key].splice(indexTobeRemoved, 1);
    }
  }
  load(params: { key: any; id?: any; syncInBackground?: any; autoSync?: any; batched?: any; syncParams?: any; }) {
    const { key, id, autoSync = true, syncInBackground = true, syncParams, batched } = params;
    return this._mapPromise.then(() => {
      if (id === undefined) {
        return this._lookupGlobalItem({
          key,
          autoSync,
          syncInBackground,
          syncParams,
        });
      } else {
        return this._lookUpInMap({
          key,
          id,
          autoSync,
          syncInBackground,
          batched,
          syncParams,
        });
      }
    });
  }
  clearAll() {
    this._s?.clear && this._s?.clear();
    this._m = this._initMap();
  }
  clearMap() {
    return (this.removeItem('map') as any).then(() => {
      this.cache = {} as any;
      this._m = this._initMap();
    });
  }
  clearMapForKey(key: string | number) {
    return this._mapPromise.then(() => {
      let tasks = (this._m.__keys__[key] || []).map((id: any) => this.remove({ key, id }));
      return Promise.all(tasks);
    });
  }
  getIdsForKey(key: string | number) {
    return this._mapPromise.then(() => {
      return this._m.__keys__[key] || [];
    });
  }
  getAllDataForKey(key: any, options: { syncInBackground: any; }) {
    options = Object.assign({ syncInBackground: true }, options);
    return this.getIdsForKey(key).then((ids: any[]) => {
      const querys = ids.map((id: any) => ({ key, id, syncInBackground: options.syncInBackground }));
      return this.getBatchData(querys);
    });
  }
}

const storage = new Storage({
  // maximum capacity, default 1000 key-ids
  size: 1000,

  // Use AsyncStorage for RN apps, or window.localStorage for web apps.
  // If storageBackend is not set, data will be lost after reload.
  storageBackend: typeof window !== 'undefined' ? window.localStorage : undefined,

  // expire time, default: 1 day (1000 * 3600 * 24 milliseconds).
  // can be null, which means never expire.
  defaultExpires: 1000 * 60 * 60 * 24 * 365,

  // cache data in the memory. default is true.
  enableCache: true,

  // if data was not found in storage or expired data was found,
  // the corresponding sync method will be invoked returning
  // the latest data.
  sync: {
    // we'll talk about the details later.
  }
});

export default storage;
