import { createWeb3Name } from '@web3-name-sdk/core'
import React, { useEffect, useRef, useState } from 'react';
import storage from './storage';
import { Console } from './console';

export const web3name = createWeb3Name({
  // rpcUrl: 'https://rpc.morphl2.io'
});

let useWeb3NamesCache = {};
export const useWeb3Names = (data: string[]) => {
  const [web3Names, setWeb3Names] = React.useState();
  // const { data: result, error, loading } = useFetchFunction(
  //   web3name.batchGetDomainNameByChainId,
  //   {
  //     addressList: data.filter(v => v).concat('0x5add06f01e73b75a4184c8cb74485bb0a897b23e'),
  //     queryChainId: 2818
  //   },
  //   {
  //     dedupingInterval: 1000 * 60 * 60 * 1,
  //     focusThrottleInterval: 1000 * 60 * 60 * 1,
  //   }
  // );

  React.useEffect(() => {
    if (!data?.[0]) return;

    const fetchData = async (data: any[]) => {
      web3name.batchGetDomainNameByChainId({
        addressList: data.filter((v: any) => v).concat('0x5add06f01e73b75a4184c8cb74485bb0a897b23e'),
        queryChainId: 2818,
      }).then(res => {
        if (!res?.length) return;

        const names = res?.reduce((acc, v, k) => {
          if (v?.domain || k === 0) {
            acc[v?.address?.toLocaleLowerCase()] = v?.domain;
          }
          return acc;
        }, {});

        setWeb3Names(names as any);

        storage.load({
          key: 'morph.web3names',
          syncInBackground: true
        }).then(async (ret: any) => {
          storage.save({
            key: `morph.web3names`,
            data: {...ret, ...names},
            expires: 1000 * 60 * 60 * 1
          });
        }).catch((err: any) => {
          storage.save({
            key: `morph.web3names`,
            data: names,
            expires: 1000 * 60 * 60 * 1
          });
        });
      }).catch(err => {
        Console.warn('web3Data 123 err', err.message)
      });
    };

    storage.load({
      key: 'morph.web3names'
    }).then(async (ret: { [x: string]: any; }) => {
      
      const names = data?.reduce((acc, v, k) => {
        if (ret[v?.toLocaleLowerCase()] || k === 0) {
          acc[v?.toLocaleLowerCase()] = ret[v?.toLocaleLowerCase()];
        }
        return acc;
      }, {});

      setWeb3Names(names as any)

      if (!names[data?.[0]?.toLocaleLowerCase()] && !useWeb3NamesCache[data?.[0]?.toLocaleLowerCase()]) {
        useWeb3NamesCache[data?.[0]?.toLocaleLowerCase()] = true;
        fetchData(data);
      }
    }).catch((err: any) => {
      if (!useWeb3NamesCache[data?.[0]?.toLocaleLowerCase()]) {
        useWeb3NamesCache[data?.[0]?.toLocaleLowerCase()] = true;
        fetchData(data);
        
      }
    });
  }, [data?.length]);
  
  return web3Names;
}


const addressListMap = new Set([]);

const AddressName = ({ address, name, chainId }: { name?: string; address: string; chainId?: number; }) => {
  // Console.log('address, name, chainId', address, name, chainId);
  
  // const [addresses, setAddresses] = useState([]);
  // const [names, setNames] = useState({});
  // const timeoutRef = useRef(null);

  // const getCachedNames = () => {
  //   const cachedNames = localStorage.getItem('cachedNames');
  //   return cachedNames ? JSON.parse(cachedNames) : {};
  // };

  // const cacheNames = (newNames: any) => {
  //   const cachedNames = getCachedNames();
  //   const updatedNames = { ...cachedNames, ...newNames };
  //   localStorage.setItem('cachedNames', JSON.stringify(updatedNames));
  // };

  // const fetchNames = async (addressList: any[]) => {
  //   const cachedNames = getCachedNames();
  //   const uncachedAddresses = addressList.filter((addr: string | number) => !cachedNames[addr]);

  //   if (uncachedAddresses.filter(v => v).length > 0) {
  //     const res = await web3name.batchGetDomainNameByChainId({
  //       addressList: uncachedAddresses.filter(v => v),
  //       queryChainId: chainId || 2818,
  //     });

  //     const newNames = res.reduce((acc: { [x: string]: any; }, { address, name }: any) => {
  //       acc[address] = name;
  //       return acc;
  //     }, {});

  //     cacheNames(newNames);
  //     setNames((prevNames: any) => ({ ...prevNames, ...newNames }));
  //   }
  // };

  // const startTimeout = () => {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }

  //   timeoutRef.current = setTimeout(() => {
  //     if (addresses.length > 0 && addresses.length < 5) {
  //       fetchNames(addresses);
  //       // setAddresses([]);
  //     }
  //   }, 5000);
  // };

  // useEffect(() => {
  //   addressListMap.add(address)
  //   setAddresses([...addressListMap]);
  //   console.log('addresses', addressListMap, address, addresses);
  //   if (addressListMap.length >= 5) {
  //     fetchNames([addressListMap]);
  //     // setAddresses([]);
  //   } else {
  //     startTimeout();
  //   }
  // }, [address]);

  // useEffect(() => {
  //   return () => {
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current);
  //     }

  //     if (addresses.length > 0) {
  //       fetchNames([...new Set(addresses)]);
  //     }
  //   };
  // }, [addresses]);

  // const cachedNames = getCachedNames();
  const currentName = '' // cachedNames[address];

  return (
    <>{name || currentName}</>
  );
};

export default AddressName;
